html,
body,
#root {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

a {
  text-decoration: none;
}

input,
textarea,
.btn {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  box-sizing: border-box;
  background-color: lightgray;
}

input:hover,
textarea:hover,
.btn:hover {
  opacity: 1;
}

.submit {
  background-color: gray;
  color: white;
}

.bold {
  font-weight: bold;
}

.text-light {
  color: gray;
}

.error {
  color: red;
  margin: 0;
}

.error-center {
  color: red;
  margin: 0;
  text-align: center;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  /* cursor: not-allowed; */
}

.active {
  background-color: red;
  /* transition: background-color 100ms linear; */
}

.loader {
  background-color: #0f0;
  color: black;
}
